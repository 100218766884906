export default {
    air_line: '航线',
    title: '航线管理',
    name: '航线名称',
    update_time: '更新时间',
    create_time: '创建时间',
    edit: '编辑航线',
    add: '添加航线',
    Action: '操作',
    preview: '预览航线',
    preview_aline: '预览',
    edit_success:"修改航线成功",
    add_success:"添加航线成功",
    delete_airline:"确认删除该航线吗"
}
