<template>
    <div>
        <v-app style="background: var(--v-list-base)">
            <v-app-bar style="background: var(--v-bg-base); max-height: 68px !important;">
                <custom-title></custom-title>
                <v-spacer></v-spacer>
                <div style="margin-right: 10px">
                    <i @click="set_lang('zh')" v-if="$i18n.locale==='en'" style="font-size: 20px; cursor: pointer;"
                       class="airport icon-zhongwen text_color--text"></i>
                    <i @click="set_lang('en')" v-else style="font-size: 20px; cursor: pointer;"
                       class="airport icon-yingwen text_color--text"></i>
                </div>
                <div style="margin-right: 20px">
                    <!--                    <i @click="set_dark_mode('1')" v-if="!$store.state.is_dark" style="font-size: 24px; cursor: pointer;" class="airport icon-shensemoshi2 text_color&#45;&#45;text"></i>-->
                    <!--                    <i @click="set_dark_mode('0')" v-else style="font-size: 28px; cursor: pointer;" class="airport icon-shensemoshi1 text_color&#45;&#45;text"></i>-->
                    <!--                    <i @click="set_dark_mode('0')" v-if="$store.state.is_dark" style="font-size: 26px; cursor: pointer;" class="airport icon-taiyang text_color&#45;&#45;text"></i>-->
                    <!--                    <i @click="set_dark_mode('1')" v-else style="font-size: 26px; cursor: pointer;" class="airport icon-yueliang1 text_color&#45;&#45;text"></i>-->
                    <i @click="set_dark_mode('0')" v-if="$store.state.is_dark" style="font-size: 22px; cursor: pointer;"
                       class="airport icon-taiyang text_color--text"></i>
                    <i @click="set_dark_mode('1')" v-else style="font-size: 22px; cursor: pointer;"
                       class="airport icon-yueliang1 text_color--text"></i>
                </div>
                <v-menu
                        bottom
                        offset-y
                        open-on-hover
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn fab text v-on="on" v-bind="attrs">
                            <v-badge
                                    bordered
                                    v-bind="attrs"
                                    v-on="on"
                                    bottom
                                    color="green"
                                    dot
                                    offset-x="10"
                                    offset-y="10"
                            >
                                <v-avatar>
                                    <img
                                            :src=" $store.state.user_info.userinfo.avatar || 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/assets/avatar.jpeg'"
                                            alt="adminUser"
                                    >
                                </v-avatar>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-list color="secondary">
                        <v-list-item style="cursor: pointer" :click="to_my">
                            <v-list-item-title>{{$t('airport.my')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="open_edit_password">
                            <v-list-item-title>{{$t('airport.change_password')}}</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item :to="{ name: 'editPersonalInfo',params:{id:user_id}}">
                             <v-list-item-title>{{$t('airport.edit_personal_info')}}</v-list-item-title>
                         </v-list-item> -->
                        <v-list-item :to="{ name: 'login'}">
                            <v-list-item-title>{{$t('airport.log_out')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-app-bar>
            <v-container fluid style="">
                <slot></slot>
            </v-container>
            <v-dialog width="800px" v-model="edit_password_dialog">
                <EditPassword @close_dialog="close_dialog" ref="edit_password"></EditPassword>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
    import EditPassword from '@/pages/auth/editpassword'
    import CustomTitle from "../components/CustomTitle";

    export default {
        name: 'layout_main',
        data() {
            return {
                user_id: null,
                edit_password_dialog: false,
            }
        },
        mounted() {
            this.user_id = JSON.parse(localStorage.getItem('user_info')).user_id
        },
        methods: {

            to_my() {
                if (this.$route.path !== 'my') {
                    this.$router.push('/my')
                }
            },
            close_dialog() {
                this.edit_password_dialog = false
            },
            open_edit_password() {
                this.edit_password_dialog = true
                this.$nextTick(() => {
                    this.$refs.edit_password.reset()
                })
            },
            set_lang(value) {
                localStorage.setItem('lang', value)
                this.$i18n.locale = value
            },
            set_dark_mode(value) {
                this.$set_dark(value)
            },
            to_home() {
                this.$store.commit('set_air_port_id', null)
                this.$router.push({path: '/'})
            },
        },
        components: {
            EditPassword,
            'custom-title': CustomTitle
        },
    }
</script>
<style lang="stylus" scoped>

</style>
