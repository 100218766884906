export default {
    number: '航班号',
    wake_interval: '飞机尾流间隔',
    land_runway: '降落跑道',
    land_airport: '降落机场',
    land_time: '降落时间',
    land_date: '降落时间',
    seat: '停靠机位',
    land_use_time: '降落用时',
    arrival_stand_time: '到达停机位时间',
    arrival_stand_use_time: '到达停机位用时',
    leave_time: '离开时间',
    leave_date: '离开日期',
    leave_runway: '离开跑道',
    leave_use_time: '离开用时',
    operation: '操作',
    add_flight: '添加航班',
    edit_flight: '修改航班',
    title: '航班列表',
    start_time:"开始时间",
    end_time:"结束时间",
    selectAll:"选择全部航班",
    selectTime:"选择时间范围内的航班",
    preview:"预览航班",
    confirm:"确定修改",
    cancel:"取消修改",
    gantt:"甘特图",
    type:"类型",
    current_time:"当前时间",
    lat:"横坐标",
    lng:"纵坐标",
    gate:"停机口/登机口",
    arrival_time:"到达停机位时间",
    runway:"降落/离开跑道号",
    predict_time:"预计降落/离开时间",
    fly_time:"实际降落/离开时间",
    slipping_time:"滑行时间",
    switch_view:"切换视图",
    year:"年",
    month:"月",
    day:"日",
    hour:"时",
    minute:"分",
    edit_success_flight:"修改航班成功",
    add_success_flight:"添加航班成功",
    delete_flight:"确认删除该航班吗",
    error_mes:"请先选择所在机场",
    edit_success:"修改成功",
    land_taxiing_tiem: '降落滑行时间',
    takeoff_time: '起飞时间',
    takeoff_taxiing_time: '起飞滑行时间',
    takeoff_runway: '起飞跑道',
    takeoff_seat: '起飞机位',
    takeoff_airport: '起飞机场',
    land_seat: '降落机位',
    origin: '出发地',
    destination: '目的地',
    time: '时间',
    to: '至',
    aircraft_id: '选择飞机',
    edit_error:"修改失败",
    edit_none:"您还未修改"
}
