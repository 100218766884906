import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: colors.blueGrey.lighten3, // #E53935
                secondary: colors.blueGrey.lighten4, // #FFCDD2
                secondary2: colors.blueGrey.lighten5,
                accent: colors.blueGrey.darken1, // #3F51B5
            },
        },
    },
});
