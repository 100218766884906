import setting from './setting'
import menus from './menus'
import airport from './airport'
import flight from './flight'
import aircraft from './aircraft'
import airline from './airline'
import nodetype from './nodetype'
import flight_information from './flight_information'
import control_region from './control_region'
import control_sector from './control_sector'
import sector_planning from './sector_planning'
import airport_security from "./airport_security"
import auth from "./auth"
import system from "./system"
import air_route from "./air_route"
export default {
    setting,
    menus,
    airport,
    flight,
    aircraft,
    airline,
    auth,
    nodetype,
    flight_information,
    control_region,
    control_sector,
    sector_planning,
    airport_security,
    system,
    air_route,
    search: 'search',
    data_loading: 'data loading...',
    confirm: 'confirm',
    reset: 'reset',
    edit:"Edit",
    preview:"Preview",
    delect:"Delect",
    delete:"Delete",
    cancel:"cancel",
    seconds:"s",
    minute:"min",
    confirm_del:"Confirm Delete",
    success:"success",
    error:"error",
    fail:"fail",
    import: 'import'

}
