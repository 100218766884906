export default {
    search_text:'User Name',
    name:'Name',
    email:'Email',
    group:'Group',
    password:'Password',
    action:'Action',
    edit_user:'Edit User',
    add_user:'Add User',

    admin:'Admin',
    teacher:'Teacher',
    student:'Student',

    edit_group:'Edit Group',
    add_group:'Add Group',
    group_name:'Group Name',

    log_number:'Log Number',
    log_type:'Operation Type',
    log_time:'Operation Time',
    model:'Operation Model',
    operators:'Operators',
    description:'Description',

    user_id:'User',
    reset:'Reset',

    title: 'Air Traffic Management Simulation And Demonstration Platform',

    loading: 'loading'
}
