export default {
    air_line: 'Air Line',
    title: 'Air Line Manage',
    name: 'Air Line Name',
    update_time: 'Update Time',
    create_time: 'Create Time',
    edit: 'Edit Air Line',
    add: 'Add Air Line',
    Action: 'Action',
    preview: 'Preview Air Line',
    preview_aline: 'Preview',
    edit_success:"Modify the route successfully",
    add_success:"Add aircraft route successfully",
    delete_airline:"Are you sure to delete this route?"
}
