<template>
    <div class="wrapper__container" @click="toHome">
        <span class="wrapper">{{$t('system.title')}}</span>
        <div @click.stop="$router.push({name: 'Version'})" class="version">
            Beta {{version}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'custom-title',
        data: () => ({
            version: '3.2.1'
        }),
        methods: {
            toHome() {
                if (this.$route.path !== '/') {
                    this.$router.push('/')
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .wrapper
        color: #000;
        font-family: standard;
        font-size: 24px;
        color: var(--v-stress_text-base);
        user-select: none

    .wrapper__container
        position: relative;
        cursor: pointer

    .version
        background: #8a8a8a;
        padding: 3px 5px 3px 5px;
        border-radius: 30px;
        font-size: 12px;
        color: white;
        transform: scale(.65);
        user-select: none;
        position: absolute;
        right: -50px;
        top: -6px
</style>
