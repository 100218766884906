
import colors from 'vuetify/lib/util/colors'

const theme = {
    // 1: {
    //     color: '#455a64',
    //     light: {
    //         top: colors.blueGrey.darken1,
    //         list: colors.blueGrey.lighten5,
    //         secondary: colors.blueGrey.lighten4,
    //         secondary2: colors.blueGrey.lighten5,
    //         accent: colors.blueGrey.darken1,
    //         primary: colors.blueGrey.darken2,
    //         level3: colors.blueGrey.lighten3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     },
    //     dark: {
    //         top: colors.blueGrey.darken4,
    //         list: colors.blueGrey.darken4,
    //         secondary: colors.blueGrey.darken3,
    //         secondary2: colors.blueGrey.darken2,
    //         accent: colors.blueGrey.darken1,
    //         primary: colors.blueGrey.lighten4,
    //         level3: colors.blueGrey.darken3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     }
    // },
    // 2: {
    //     color: '#424242',
    //     dark: {
    //         top: colors.grey.darken4, // #E53935
    //         list: colors.grey.darken4,
    //         primary: colors.grey.lighten2, // #E53935
    //         secondary: colors.grey.darken3, // #FFCDD2
    //         secondary2: colors.grey.darken2,
    //         accent: colors.blueGrey.darken1, // #3F51B5
    //         level3: colors.blueGrey.darken3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     },
    //     light: {
    //         top: colors.grey.darken1, // #E53935
    //         list: colors.grey.lighten5,
    //         primary: colors.grey.darken3, // #E53935
    //         secondary: colors.grey.lighten1, // #FFCDD2
    //         secondary2: colors.grey.lighten2,
    //         accent: colors.grey.lighten3, // #3F51B5
    //         level3: colors.grey.lighten3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     }
    // },
    // 3: {
    //     color: '#90caf9',
    //     dark: {
    //         top: colors.blue.darken4, // #E53935
    //         list: colors.blue.darken4,
    //         primary: colors.blue.lighten3, // #E53935
    //         secondary: colors.blue.darken3, // #FFCDD2
    //         secondary2: colors.blue.darken2,
    //         accent: colors.blue.darken1, // #3F51B5
    //         level3: colors.blue.darken3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     },
    //     light: {
    //         top: colors.blue.lighten2, // #E53935
    //         list: colors.blue.lighten5,
    //         primary: colors.blue.darken3, // #E53935
    //         secondary: colors.blue.lighten3, // #FFCDD2
    //         secondary2: colors.blue.lighten4,
    //         accent: colors.blue.lighten5, // #3F51B5
    //         level3: colors.blue.lighten3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     }
    // },
    // 4: {
    //     color: '#ffe0b2',
    //     dark: {
    //         top: colors.orange.darken4, // #E53935
    //         list: colors.orange.darken4,
    //         primary: colors.orange.lighten4, // #E53935
    //         secondary: colors.orange.darken3, // #FFCDD2
    //         secondary2: colors.orange.darken2,
    //         accent: colors.orange.darken1, // #3F51B5
    //         level3: colors.orange.darken3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     },
    //     light: {
    //         top: colors.orange.lighten2, // #E53935
    //         list: colors.orange.lighten5,
    //         primary: colors.orange.darken4, // #E53935
    //         secondary: colors.orange.lighten3, // #FFCDD2
    //         secondary2: colors.orange.lighten4,
    //         accent: colors.orange.lighten5, // #3F51B5
    //         level3: colors.orange.lighten3,
    //         bg:'#161d31',
    //         btn_text: '#06BB9A'
    //     }
    // },

    5: {
        color: '#455a61',
        light: {
            top: '#f8f8f8',
            list: '#f8f8f8',
            secondary: '#ffffff',
            secondary2: colors.blueGrey.lighten5,
            accent: colors.blueGrey.darken1,
            primary: colors.blueGrey.darken2,
            level3: colors.blueGrey.lighten3,
            bg:'#f8f8f8',
            btn_text: '#06BB9A',
            item_card: '#f0f0f1',
            text_color: '#82868b',
            shadow_color: '#82868b',
            stress_text: '#525252'
        },
        dark: {
            secondary: '#283046',
            top: '#161d31',
            list: '#343d55',
            secondary2:'#161d31',
            accent: '#161d31',
            primary: '#ddd',
            level3:'#161d31',
            bg:'#161d31',
            btn_text: '#06BB9A',
            item_card: '#333A4E',
            text_color: '#82868B',
            shadow_color: '#000',
            stress_text: '#dfdfdf'
        }
    },
}


export default theme_index => {
    try{
        return theme[theme_index]
    }catch(e) {
        return theme[1]
    }
}

export const theme_list = theme;
