export default {
    login:"Login",
    reset:"Reset",
    username:"username",
    password:"password",
    phone:"phone",
    address:"address",
    re_password:"repeat password",
    title:"userList",
    search:"User Name",
    email:"Email",
    is_staff:"is staff",
    is_superUser:"is superUser",
    date_joined:"Date Joined",
    last_login:"last login",
    first_name:"first name",
    last_name:"last name",
    is_active:"is active",
    edit_user:'edit user',
    add_user:'add user',
    new_password:"new password",
    old_password:"old password",
    confirm_password:"confirm password",
    change_password:"change password",
    user_new_password: 'User New Password',
    re_user_new_password: 'Repeat User New Password',
    edit_user_password: 'Change User Password',
    newRules:'New password is required',
    sameRules:'The new password cannot be the same as the old password',
    oldRules:"Old password is required",
    confirmRules:"Confirm password is required",
    compareRules:"Password must be match",
    changing:'You are changing the password of user ',
    extra_words:'',

    modify_role:'Modify Role',
    role:'Role',
    edit_success_psd:"Password changed successfully",
    fail_log:"Failed to log in",

    status: 'Status',
    normal: 'Normal',
    disable: 'Disable',
    not_yet: 'Not yet',
    administrator: 'Administrator',
    yes: 'Yes',
    no: 'No',
    role: 'Role',


    edit_success_group:"Succeeded in modifying the group",
    add_success_group:"Adding a group succeeded",
    delete_group:"Are you sure to delete the group",
    delete_user:"Are you sure to delete the user",
    psd_length:"The password length is too short",
    inconsistent:"The passwords are inconsistent twice",
    email_error:"The email is wrong, please re-enter",
    success_user_info:"Succeeded in modifying user information",
    psd_length_less:"Password cannot be less than 6 digits",
    add_user_info:"Succeeded in adding user information",
    empty_group:"User group is not empty",
    upload_img_hint: 'click or drag to upload an avatar',
    upload:'click to upload',
    edit_user_info:"Modify personal information successfully"

}
