import Vue from 'vue'
import VueI18n from "vue-i18n";
import lang_zh from './lang/zh/'
import lang_en from './lang/en/'


Vue.use(VueI18n)


const i18n = new VueI18n({
    locale: 'en',
    messages: {
        zh: lang_zh,
        en: lang_en
    }
})


export default i18n
