export default {
    title: 'Flight Information List',
    name: 'Flight Information Name',
    abbreviation: 'Abbreviation',
    min_vertical: 'Min Vertical',
    max_vertical: 'Max Vertical',
    create_time: 'Create Time',
    update_time: 'Update Time',
    operation: 'Operation',
    control:'control area',
    preview:"preview the FIR",
    Preview3d:"3D Demonstrate",
    sector_name:"sector name",
    load:"load",
    remark:"remark",
    first_step:"first",
    second_step:"second",
    third_step:"third",
    edit_success_flightinfolist:"Flight information region modified successfully",
    add__success_flightinfolist:"Flight information region added successfully"
}
