import setting from './setting'
import menus from './menus'
import airport from './airport'
import flight from './flight'
import aircraft from './aircraft'
import airline from './airline'
import nodetype from './nodetype'
import flight_information from './flight_information'
import control_region from './control_region'
import control_sector from './control_sector'
import sector_planning from './sector_planning'
import airport_security from "./airport_security"
import auth from "./auth"
import system from "./system";
import air_route from "./air_route"
export default {
    setting,
    menus,
    airport,
    flight,
    aircraft,
    airline,
    nodetype,
    flight_information,
    control_region,
    control_sector,
    sector_planning,
    airport_security,
    auth,
    system,
    air_route,
    search: '筛选',
    data_loading: '数据加载中...',
    confirm: '确认',
    reset: '重置',
    edit:"编辑",
    preview:"预览",
    delect:"删除",
    delete:"删除",
    cancel:"取消",
    seconds:"秒",
    minute:"分",
    confirm_del:"确认删除",
    success:"成功",
    error:"错误",
    fail:"失败",
    import: '导入'
}
