export function requestFullScreen (element) {
    //当前全屏状态
    let isFullSceen = document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen

    //多个游览器内核的全屏判断
    let requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen
    if (requestMethod) {
        if (isFullSceen) { //根据是否处于全屏状态 来进行切换
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            // if (typeof cfs != "undefined" && cfs) {
            //     cfs.call(el);
            // }
        } else {
            requestMethod.call(element) //打开全屏
        }
    } else if (typeof window.ActiveXObject !== 'undefined') {
        //低版本的进入全屏和取消全屏都是这个方法
        var wscript = new ActiveXObject('WScript.Shell')
        if (wscript !== null) {
            wscript.SendKeys('{F11}')
        }
    }
    //返回操作后的全屏状态
    return !isFullSceen
}
