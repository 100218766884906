export default {
    search_text:'用户名称',
    name:'用户名',
    email:'邮箱',
    group:'角色',
    password:'密码',
    action:'操作',
    edit_user:'修改角色',
    add_user:'添加角色',

    admin:'管理员',
    teacher:'老师',
    student:'学生',

    edit_group:'修改角色',
    add_group:'添加角色',
    group_name:'角色名称',

    log_number:'日志编号',
    log_type:'操作类型',
    log_time:'操作日期',
    model:'操作模块',
    operators:'操作人员',
    description:'备注',

    user_id:'用户',
    reset:'重置',

    title: '空中交通管理仿真和演示平台',

    loading: '加载中'
}
