export default {
    title: '管制区列表',
    edit_action: '编辑管制区',
    add_action: '添加管制区',
    name: '管制区名称',
    update_time: '更新时间',
    create_time: '创建时间',
    operation: '操作',
    add: '添加',
    edit: '修改',
    min_vertical: '最小高度',
    max_vertical: '最大高度',
    control_sector:'管制扇区',
    edit_success_control:"修改管制区成功",
    add_success_control:"添加管制区成功",
    delect_con:"确认删除该管制区吗"
}
