export default {
    search:'飞机类型',
    Type: "飞机类型",
    Manufacturer: '飞机厂商',
    Min_velocity: "最小时速",
    Max_velocity: "最大时速",
    Avg_velocity: "平均时速",
    Date_manufacture: "制造日期",
    Count_seat: "预设乘客数量",
    Customer_service: "预设机组人员数量",
    Mileage: "行驶里程",
    Size: " 飞机大小",
    Flight_time: "运行时间",
    Max_fuel: "油箱",
    Max_luggage: "可托运行李数量",
    Furthest: "单次最远里程",
    Status: "状态",
    Action:"操作",
    name: '名称',
    Submit:"提交",
    Reset:"重置",
    title: '飞机列表',
    add_aircraft:"添加飞机",
    edit_aircraft:"修改飞机",
    AirCraftType:"飞机类型",
    AirCraftManufacturer:"飞机厂商",
    Type_title:"飞机类型列表",
    Manufacturer_title:"飞机厂商列表",
    Type_name:"飞机类型",
    Manufacturer_name:"飞机厂商",
    createTime:"创建时间",
    updateTime:"更新时间",
    add_type: '添加飞机类型',
    edit_type: '修改飞机类型',
    add_manufacturer: '添加飞机厂商',
    edit_manufacturer: '修改飞机厂商',
    edit_success:"修改飞机类型成功",
    add_success:"添加飞机类型成功",
    delete:"确认删除该类型吗?",
    edit_success_aircraft:"修改飞机成功",
    add_success_aircraft:"添加飞机成功",
    delete_aircraft:"确认删除该飞机吗?",
    manufacturer_name: '厂商名称',
    type_name: '类型名称',
    normal: '正常',
    abnormal: '异常'
}
