export default {
    title: '保障车信息列表',
    edit_vehicle_info:'修改保障车信息',
    add_vehicle_info:'添加保障车信息',
    number:'编号',
    garage_number:'车库编号',
    refueling_speed:'加油速度',
    type:'类型ID',
    create_time:'创建时间',
    update_time:'修改时间',
    delete_time:'删除时间',
    operation: '操作',

    type_title:'保障车类型列表',
    edit_vehicle_type:'修改保障车信息列表',
    add_vehicle_type:'添加保障车信息列表',
    name:'保障车类型名称',

    scheduler_title:'机场保障排班表',
    edit_scheduler:'修改机场保障排班',
    add_scheduler:'添加机场保障排班',
    airport_id:'机场',
    car_id: '保障车辆',
    start_point_id:'开始坐标点',
    end_point_id:'目标任务点',
    await_time:'等待时间(秒)',
    is_end:'是否结束',
    start_time:"开始时间",
    end_time:"结束时间",
    selectAll:"选择全部排班表",
    selectTime:"选择时间范围内的排班表",
    preview:"预览机场保障排班表",
    gantt:"甘特图",
    point:"任务点",
    edit_success_ass:"修改保障车排班表成功",
    add_success_ass:"添加保障车排班表成功",
    delete_ass:"确认删除保障车排班表吗",
    message_time:"开始时间不能大于结束时间",
    message_null:"排班任务不能为空哦",
    none_total:"没有填写完整",
    last_none:"最后一条任务不能删除",
    select_none:"您还没有选择机场",
    edit_success_air_sec:"修改保障车信息成功",
    add_success_air_sec:"添加保障车信息成功",
    delete_air_sec:"确认删除保障车信息吗",
    edit_success_vehT:"修改保障车类型成功",
    add_success_vehT:"添加保障车类型成功",
    delete_vehT:"确认删除保障车类型吗",
}
