export default {
  login:"登录",
  reset:"重置",
  username:"用户名",
  password:"密码",
  phone:"手机号",
  address:"地址",
  re_password:"重复密码",
  title:"用户列表",
  search:"用户名",
  email:"邮箱",
  is_staff:"是否为职员",
  is_superUser:"是否为超级管理员",
  date_joined:"加入日期",
  last_login:"最后登录时间",
  first_name:"名",
  last_name:"姓",
  is_active:"是否活跃",
  edit_user:'修改用户',
  add_user:'添加用户',
  new_password:"新密码",
  old_password:"原密码",
  confirm_password:"确认密码",
  change_password:"修改密码",
  user_new_password: '用户新密码',
  re_user_new_password: '重复用户新密码',
  edit_user_password: '修改用户密码',
  newRules:'请输入新密码',
  sameRules:"新密码不能和旧密码一致",
  oldRules:"请输入原密码",
  confirmRules:"请再次输入密码",
  compareRules:"两次输入密码不一致",
  changing:"您正在修改用户",
  extra_words:"的密码",

  modify_role:'修改角色',
  role:'角色',
  edit_success_psd:"修改密码成功",
  fail_log:"登录失败",

  status: '状态',
  normal: '正常',
  disable: '禁用',
  not_yet: '暂无',
  administrator: '管理员',
  yes: '是',
  no: '否',
  role: '角色',


  edit_success_group:"修改组权限成功",
  add_success_group:"添加组成功",
  delete_group:"确认删除该角色吗",
  delete_user:"确认删除该用户吗",
  psd_length:"密码长度过短",
  inconsistent:"两次密码不一致",
  email_error:"邮箱有误请重新输入",
  success_user_info:"修改用户信息成功",
  psd_length_less:"密码不能小于6位",
  add_user_info:"添加用户信息成功",
  empty_group:"角色名称不为空",
  upload_img_hint: '点击或者拖拽上传头像',
  upload:'点击上传',
  edit_user_info:"修改个人信息成功"
}
