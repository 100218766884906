export default {
    title: 'Node Type List',
    edit: 'Edit',
    add: 'Add',
    name: 'Type Name',
    update_time: 'Update Time',
    create_time: 'Create Time',
    Action: 'action',
    edit_action: 'Edit Node Type Info',
    add_action: 'Add Node Type Info'

}
