export default {
    title: 'Control Region List',
    edit_action: 'Edit Control Info',
    add_action: 'Add Control Info',
    name: 'Control Region Name',
    update_time: 'Update Time',
    create_time: 'Create Time',
    operation: 'Operation',
    add: 'Add',
    edit: 'Edit',
    min_vertical: 'Min Vertical',
    max_vertical: 'Max Vertical',
    control_sector:'Control Sector',
    edit_success_control:"Succeeded in modifying the control area",
    add_success_control:"Succeeded in adding control area",
    delect_con:"Are you sure to delete the control area"
}
