export default {
    airport_name: 'Airport Name',
    place: 'Place',
    area: 'Area',
    boarding_gate: 'Boarding Gate',
    operation: 'Operation',
    preview: 'Preview',
    edit: 'Edit',
    add_airport: 'Add Airport',
    search:'Airport Name',
    Name:'Name',
    Area: "Area",
    Lat: "Lat",
    Lng: "Lng",
    Apron_number: "ApronNumber",
    Runway_number: "RunwayNumber",
    Country: "Country",
    Province: "Province",
    City: "City",
    Height: "Height",
    Weight: "Weight",
    Status: "Status",
    Submit:"Submit",
    Reset:"Reset",
    Action:"Action",
    Place:"Place",
    title: 'Airport List',
    airportNode:"Airport Node List",
    securityNode:"Support Vehicle Node List",
    Search_Title:"Airport Node Name",
    node_type: "Node Type",
    node_name: "Node Name",
    transverse_coordinate: "Transverse Coordinate",
    longitudinal_coordinate: "Longitudinal Coordinate",
    lat: "Lat",
    lng: "Lng",
    match_model: "Match Model",
    remote_stand: "Remote Stand",
    airport_id: "AirportId",
    NodeTitle:"Airport Node Group",
    securityNodeTitle:"Support Vehicle Node Group",
    add_Node:"Add Node",
    edit_Node:"Edit Node",
    selectNode:"Select Node",
    name:"Name",
    type:"Type",
    group:"Group",
    order:"Order",
    road_type:"Road Type",
    attach:"Attach",
    start_time:"Start Time",
    end_time:"End Time",
    start_date:"Start Date",
    end_date:"End Date",
    preview_airport:"Preview",
    preview_car:"Preview the Protection Vehicle",
    airport_node:"Airport Node",
    security_node:"Support Vehicle Node",
    node_data:"Node",

    edge:"edge",
    start_id:"start_id",
    end_id:"end_id",
    is_two_way:"is_two_way",
    yes:'Yes',
    no:'No',
    edit_edge:"Edit Edge",
    add_edge:"Add Edge",
    airportEdge:"Airport Edge List",
    airport_edge:'Airport Edge',

    change_password:'Change Password',
    log_out:'Log Out',
    my:'personal center',
    edit_personal_info:"Edit Personal info",

    flight_information_area:'Flight Information Area',
    approach_and_departure_route_optimization:'Approach And Departure Route Optimization',
    route_conflict_detection:'Route Conflict Detection',
    visualization:'3D Visualization Of Electromagnetic Fields',
    freeways:'Freeways',
    user_management:'User Management',
    role_management:'Role Management',
    log_management:'Log Management',

    tarmac:'Tarmac',
    runway:'Runway',

    flight_management:'Flight Management',
    airport_security:'Airport Security',

    details:"Seat occupancy details",
    total_time:"Total Slipping Time",
    alert_text:"The stand is not occupied in the current time period",
    conflict:"Conflict stand",
    No_conflict:"No Conflict",
    will:'will conflict at',
    gate_position:"gate position",
    be_conflict:" ",

    Node:"Node",
    Edge:"Edge",
    Enter:"Enter",

    edit_success_edge:"Modifying edge succeeded",
    add_success_edge:"Edge added successfully",
    delete_edge:"Are you sure to delete edges",
    delete_airport:"Are you sure to delete the airport",
    error_id:'No airport ID was obtained',
    select_time_range:"Please select the time range",
    edit_success_node:"Modifying node succeeded",
    add_success_node:"Node added successfully",
    delete_node:"Are you sure to delete node",
    edit_success_node_type:"Modifying node type succeeded",
    add_success_node_type:"Node type added successfully",
    delete_node_type:"Are you sure to delete node type",

    aircraft_list: 'Aircraft List',

    airport_position: 'Airport Position',

    current_airport_information: 'airport info',
    EnName: 'English name',

    upload_img_hint: 'Airport picture, chrome and Firefox support dragging and dropping pictures to upload to this area',
    upload_img_once:"Only one image can be uploaded at a time",
    flight_gantt: 'Flight Gantt',
    ICAO: 'ICAO'
}
