import { request } from '@/utils'
// 登录
export const login = data => request.post("/api/login/", data);
// 修改用户密码
export const chang_password = data => request.post("/api/edit_self_password/", data);
// 用户列表
export const user_list = params => request.get("/api/user/",{params});
export const edit_user = data => request.put(`/api/user/${data.id}/`,data);
export const edit_user_password = data => request.patch(`/api/user/${data.id}/`,data);
export const add_user = data => request.post(`/api/user/`,data);

export const edit_role = data => request.post(`/api/set_group/`,data)

// 个人信息
export const personal_list = params => request.get("/api/my/",{params});
export const edit_personal = data => request.patch(`/api/my/`,data);