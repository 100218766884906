export default {
    totalTitle:"Algorithm Parameter Input Interface",
    firstTitle:"Airspace and Algorithm",
    secondTitle:"Intermediate process parameters",
    thirdTitle:"Parameters related to workload calculation",
    fourthTitle:"Constraint related parameters",
    fifthTitle:"Algorithm related parameters",
    algorithmSelection: "Algorithm selection",
    airspaceSelection: "Airspace selection",
    parameterInput: "Series 3D point coordinates",
    flightPathNumber: "Corresponding track data number",
    grigSizeLength: "Grid size(Length)",
    grigSizeWidth: "Grid size(Width)",
    grigSizeHeight: "Grid size(Height)",
    sectorNumber: "Divided the number of sectors ",
    heightLayer: "Dividing altitude layer",
    VoronoiNumber: "Number of the Voronoi",
    flightFlowTime: "Time required to monitor one minute flight flow",
    resolveConflictTime: "Time required to resolve an aircraft conflict",
    controlTransferTime: "The time required for a control transfer",
    maxLoad: "Maximum workload of a single sector",
    minStayTime: "Minimum dwell time in sector",
    shortestDistance: "Minimum residence time in a sector",
    allowReenter: "Percentage of reentry events allowed",
    allowShortCross: "Percentage of short transit time allowed",
    AllowSectorImbalance: "Allowable sector imbalance",
    evolutionaryAlgebra: "evolutionaryAlgebra",
    populationSize: "populationSize",
    crossProbability: "crossProbability",
    variationProbability: "variationProbability",
    initCombinationCrossProbability:"Initial combination crossover probability",
    SBXdistributionindex: "SBX Distribution Index",
    submit:"summit",
    reset:"reset"
}
