export default {
    title: 'Protection Vehicle Info List',
    edit_vehicle_info:'Edit Vehicle Info',
    add_vehicle_info:'Add Vehicle Info',
    number:'Number',
    garage_number:'Garage number',
    refueling_speed:'Refueling speed',
    type:'Type ID',
    create_time:'Create Time',
    update_time:'Update Time',
    delete_time:'Delete Time',
    operation: 'Operation',

    type_title:'Protection Vehicle Type List',
    edit_vehicle_type:'Edit Vehicle Type',
    add_vehicle_type:'Add Vehicle Type',
    name:'Name',

    scheduler_title:'Scheduler Title',
    edit_scheduler:'Edit Scheduler',
    add_scheduler:'Add Scheduler',
    airport_id:'Airport',
    start_point_id:'Start Point Id',
    end_point_id:'Target task',
    start_time:'Start Time',
    await_time:'Await Time(s)',
    car_id: 'Car',
    is_end:'Is End',
    end_time:"End Time",
    selectAll:"Select all schedules",
    selectTime:"Select the schedule in the time range",
    preview:"Preview the airport security schedule",
    gantt:"Gantt",
    point:"Task points",
    edit_success_ass:"Modifying the maintenance truck scheduling list succeeded",
    add_success_ass:"Maintenance truck scheduling list added successfully",
    delete_ass:"Are you sure to delete the maintenance truck scheduling",
    message_time:"The start time cannot be later than the end time",
    message_null:"The schedule can't be empty",
    none_total:"not complete",
    last_none:"The last task cannot be deleted",
    select_none:"You have not chosen an airport",
    edit_success_air_sec:"Modifying the security vehicle information succeeded",
    add_success_air_sec:"Security vehicle information  added successfully",
    delete_air_sec:"Are you sure to delete the security vehicle information",
    edit_success_vehT:"Modifying the  support car type succeeded",
    add_success_vehT:"Support car type added successfully",
    delete_vehT:"Are you sure to delete the support car type",
}
