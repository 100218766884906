export default{
    details:"当前区域详情",
    current_region:"当前区域",
    temperature:"温度",
    weather:"天气",
    wind_direction:"风向",
    wind_power:"风力",
    air_quality:"空气质量",
    airport_list:"机场列表",
    recalculate:"重新计算",
    standard_procedure:"标准程序",
    approach:"进场",
    departure:"离场",
    Optimized_program:"优化后程序",
    Other_Settings:"其它设置",
    Free_Roaming_View:"自动视角",
    display_the_airspace:"显示空域",
    input_parameter:"输入参数",
    Maximum_climbing_angle:"最大爬升角",
    Minimum_climbing_angle:"最小爬升角",
    maximum_drop_angle:"最大下降角",
    Minimum_drop_angle:"最小下降角",
    Minimum_horizontal_interval:"最小水平间隔",
    Minimum_vertical_interval:"最小竖直间隔",
    point:"汇聚（发散）点间最小间隔",
    SID:"SID有效长度",
    STAR:"STAR有效长度",
    approach_tur:"进场最大转弯角度",
    departure_tur:"离场最大转弯角度",
    Route_weighted:"航路加权长权值",
    Net_length:"航路净长权值",
    grid:"网格长度",
    expansion:"航路扩展方式",
    max_annealing_temperature:"最大退火温度",
    min_annealing_temperature:"最小退火温度",
    Cooling_rate:"降温速率",
    iterations:"迭代次数",
    replacing_matrix:"替换矩阵的概率",
    substitution:"替换点的概率",
    thredetourshold:"航路绕弯阈值",
    Arc_chord:"弧弦比阈值",
    degree: '度',
    nm: '海里',
    ft: '英尺',
    show_obstacle: '显示障碍物',
    show_aircraft: '显示飞机',
    loading:"加载中",
    show_tracks: '显示航迹'
}
