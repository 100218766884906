export default {
    search:'Aircraft Type',
    Type: "Type",
    Manufacturer: 'Manufacturer',
    Min_velocity: "MinVelocity",
    Max_velocity: "MaxVelocity",
    Avg_velocity: "Avgvelocity",
    Date_manufacture: "DateManufacture",
    Count_seat: "CountSeat",
    Customer_service: "CustomerService",
    Mileage: "Mileage",
    Size: "Size",
    Flight_time: "FlightTime",
    Max_fuel: "MaxFuel",
    Max_luggage: "MaxLuggage",
    Furthest: "Furthest",
    Status: "Status",
    Action:"Action",
    Submit:"Submit",
    Reset:"Reset",
    title: 'Aircraft List',
    add_aircraft:"Add Aircraft",
    edit_aircraft:"Edit Aircraft",
    AirCraftType:"AirCraft Type Name",
    AirCraftManufacturer:"AirCraft Manufacturer Name",
    Type_title:"AirCraft Type List",
    Manufacturer_title:"AirCraft Manufacturer List",
    Type_name:"Type Name",
    createTime:"Create Time",
    updateTime:"Update Time",
    name: 'Name',
    add_type: 'Add Type',
    edit_type: 'Edit Type',
    Manufacturer_name:"Manufacturer Name",
    add_manufacturer: 'Add Aircraft Manufacturer',
    edit_manufacturer: 'Edit Aircraft Manufacturer',
    edit_success:"The modification of the aircraft type was successful",
    add_success:"The addition of the aircraft type was successful",
    delete:"Are you sure to delete this type?",
    edit_success_aircraft:"The modification of the aircraft  was successful",
    add_success_aircraft:"The addition of the aircraft  was successful",
    delete_aircraft:"Are you sure to delete this plane?",
    manufacturer_name: 'Manufacturer Name',
    type_name: 'Type Name',
    normal: 'Normal',
    abnormal: 'Abnormal'
}
