export default {
    name: 'Control Sector Name',
    min_vertical: 'Min Vertical',
    max_vertical: 'Max Vertical',
    start_service_time: 'Start Service Time',
    end_service_time: 'End Service Time',
    control_region: 'Control Region',
    area: 'Sector Area',
    monitoring_load: 'Monitoring Load',
    coordinated_load: 'Coordinated Load',
    conflict_load: 'Conflict Load',
    title: 'Control Sector List',
    add_action: 'Add Control Sector',
    edit_action: 'Edit Control Sector',
    add: 'Add',
    edit: 'Edit',
    update_time: 'Update Time',
    create_time: 'Create Time',
    operation: 'Operation',
    edit_success_control_sector:"The modification of the control sector was successful",
    add_success_control_sector:"The addition of a control sector was successful",
    delect_con_sec:"Are you sure to delete the controlled sector"
}
