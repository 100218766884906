<template>
    <v-card  color="secondary">
        <v-card-title> {{$t("auth.change_password")}}
        </v-card-title>
        <v-container style="padding: 30px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                v-model="old_password"
                                :label="$t('auth.old_password')"
                                type="password"
                                :rules=" [v => !!v || $t('auth.oldRules')]"
                                required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="new_password"
                                :label="$t('auth.new_password')"
                                :rules="[v => !!v || $t('auth.newRules'),(this.new_password !== this.old_password) || $t('auth.sameRules')]"
                                type="password"
                                required
                        ></v-text-field>
                    </v-col>
                    <!-- 比较 -->
                    <v-col cols="12">
                        <v-text-field
                                v-model="confirm_password"
                                :label="$t('auth.confirm_password')"
                                :rules="[v => !!v || $t('auth.confirmRules'),(this.new_password === this.confirm_password) || $t('auth.compareRules')]"
                                type="password"
                                required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-2">
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" text @click="reset">{{$t('auth.reset')}}</v-btn>
                    <v-btn small :disabled="!valid" color="primary" text @click="validate">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>
<script>
    import {chang_password} from '@/api/auth'

    export default {
        props: ['edit_password_dialog'],
        data() {
            return {
                valid: true,
                old_password: '',
                new_password: '',
                confirm_password: ''
            }
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    chang_password({old_password: this.old_password, new_password: this.new_password}).then((res) => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('auth.edit_success_psd'),
                        })
                        this.$emit('close_dialog')
                        this.$router.push({name: 'login'})
                    })
                }
            },
            reset() {
                this.$refs.form.reset();
            }
        },

    }
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl';
</style>
