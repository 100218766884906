export default {
    index: '首页',
    index_info: '主页信息',
    index1: '首页1',
    index_info2: '主页信息2',
    index_info3: '主页信息3',
    airport_management: '机场管理',
    airport_list: '机场列表',
    airport_edit: '编辑机场数据',
    airport_map:"选择机场",
    airport_site_plan: '机场平面图',
    airport_add: '添加机场数据',
    data_analysis: '数据分析',
    aircraft_management: '飞机管理',
    aircraft_list: '飞机列表',
    aircraft_edit: '编辑飞机数据',
    aircraft_add: '添加飞机数据',
    flight_management:"航班管理",
    flight_list:"航班列表",
    flight_preview: '航班预览',
    airport_preview: '机场预览',
    aircraft_type:"飞机类型",
    aircraft_manufactures:"飞机厂商",
    airline_management: '航线管理',
    airline_title: '航线管理',
    flight_path_preview: '预览航线',
    airport_node:"机场节点",
    nodetype_title: '节点类型列表',
    flight_information: '飞行情报区',
    flight_information_list: '飞行情报区列表',
    control_region: '管制区',
    control_sector: '管制扇区',
    flight_information_preview:"情报区预览",
    control_region_preview:"管制区预览",
    control_sector_preview:"管制扇区预览",
    sector_planning:"扇区划分参数",
    airport_cesium: '实景预览',
    aircraft_docking_preview:"飞机停靠预览",
    airport_security:'机场保障',
    protection_vehicle_info_list:'保障车信息列表',
    protection_vehicle_type_list:'保障车类型列表',
    vehicle_preview:'保障车路线预览',
    airport_security_scheduler:'机场保障排班表',
    protection_vehicle_preview:'保障车停靠预览',
    airport_security_scheduler_preview:"保障车排班表预览",
    flight_conflict: '航路冲突',
    system_functions:"系统功能",
    user_list:"用户列表",
    edit_password:"修改密码",
    air_route_optimization: '进离场程序优化',
    air_route: '航路优化',

    system_management: '系统管理',
    system_user_management: '角色管理',
    system_role_permissions: '角色权限',
    system_log_management: '日志管理',

    free_route_index: '自由航路预览',
    free_route: '自由航路',
    version: '版本'
}
