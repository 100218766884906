export default {
    title: '飞行情报区列表',
    name: '飞行情报区名称',
    abbreviation: '简称',
    min_vertical: '最小高度',
    max_vertical: '最大高度',
    create_time: '创建时间',
    update_time: '更新时间',
    operation: '操作',
    control:'管制区',
    preview:"预览情报区",
    Preview3d:"3D演示",
    sector_name:"扇区名称",
    load:"负载",
    remark:"备注",
    first_step:"第一步",
    second_step:"第二步",
    third_step:"第三步",
    edit_success_flightinfolist:"修改情报区成功",
    add__success_flightinfolist:"添加情报区成功"
}
