import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        nav_position: 'left',
        nav_show: true,
        top_nav_data: [],
        user_info: {},
        select_air_port_id: null,
        map_styles: [],
        is_dark: true
    },
    mutations: {
        set_dark(state, payload) {
            state.is_dark = payload
        },
        set_map_styles(state, payload) {
            state.map_styles = payload
        },
        set_air_port_id(state, payload) {
            state.select_air_port_id = payload
        },
        set_user_info(state, payload) {
            state.user_info = payload
        },
        set_nav_position(state, payload) {
            localStorage.setItem('nav_position', payload)
            state.nav_position = payload
        },
        set_nav_show(state, payload) {
            localStorage.setItem('nav_show', payload)
            state.nav_show = payload == '1' ? true : false
        },
        push_top_nav(state, payload) {
            for (let i of state.top_nav_data) {
                if (i.path === payload.path) {
                    return
                }
            }
            state.top_nav_data.push(payload)
        },
        close_top_nav(state, payload) {
            state.top_nav_data.splice(payload, 1)
        },
        reset_nav_position(state, payload) {
            const cache = state.top_nav_data[payload.origin_position]
            state.top_nav_data.splice(payload.origin_position, 1)
            state.top_nav_data.splice(payload.new_position, 0, cache)
        }
    },
})

export default store
