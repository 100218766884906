export default {
    name: '管制扇区名称',
    min_vertical: '最小高度',
    max_vertical: '最大高度',
    start_service_time: '开始服务时间',
    end_service_time: '结束服务时间',
    control_region: '所属管制区',
    area: '管制扇区面积',
    monitoring_load: '监视负荷',
    coordinated_load: '协调负荷',
    conflict_load: '冲突负荷',
    title: '管制扇区列表',
    add_action: '添加管制扇区',
    edit_action: '修改管制扇区',
    add: '添加',
    edit: '修改',
    update_time: '更新时间',
    create_time: '创建时间',
    operation: '操作',
    edit_success_control_sector:"修改管制扇区成功",
    add_success_control_sector:"添加管制扇区成功",
    delect_con_sec:"确认删除该管制扇区吗"
}
