export default {
    index:'Index',
    index_info: 'Index Info',
    index1: 'Index1',
    index_info2: 'Index Info2',
    index_info3: 'Info Info3',
    airport_management: 'Airport Management',
    airport_list: 'Airport List',
    airport_edit: 'Edit Airport',
    airport_map:"Search Airport",
    airport_site_plan: 'Airport Site Plan',
    airport_add: 'Add Airport',
    data_analysis: 'Data analysis',
    aircraft_management: 'Aircraft Management',
    aircraft_list: 'Aircraft List',
    aircraft_edit: 'Edit Aircraft',
    aircraft_add: 'Add Aircraft',
    flight_management:"Flight Management",
    flight_list:"Flight List",
    flight_preview: 'Flight Preview',
    airport_preview: 'Airport Preview',
    aircraft_type:"Aircraft Type",
    aircraft_manufactures:"Aircraft Manufactures",
    airline_management: 'Airline Management',
    airline_title: 'Air Line Management',
    flight_path_preview: 'preview air line',
    airport_node:"Airport Node",
    nodetype_title: 'Node Type List',
    flight_information: 'Flight Information',
    flight_information_list: 'Flight Information List',
    control_region: 'Control Region',
    control_sector: 'Control Sector',
    flight_information_preview:"Flight Information Preview",
    control_region_preview:"Control Region Preview",
    control_sector_preview:"Control Sector Preview",
    sector_planning:"Sector Partition Parameters",
    airport_cesium: 'Real Preview',
    aircraft_docking_preview:"Aircraft Docking Preview",
    airport_security:'Airport Security',
    protection_vehicle_info_list:'Protection Vehicle Info',
    protection_vehicle_type_list:'Protection Vehicle Type',
    vehicle_preview:'Vehicle Preview',
    airport_security_scheduler:'Airport Security Scheduler',
    protection_vehicle_preview:'Protection Vehicle Preview',
    flight_conflict: 'Flight Conflict',
    airport_security_scheduler_preview:"airport Security Scheduler Preview",
    system_functions:"System Functions",
    user_list:"User List",
    edit_password:"Edit Password",
    air_route_optimization: 'Entry and exit optimization',
    air_route: 'air route optimization',

    system_management: 'System Management',
    system_user_management: 'System User Management',
    system_role_permissions: 'System Role Permissions',
    system_log_management: 'System Log Management',

    free_route_index: 'Free Route Preview',
    free_route: 'Free Route',
    version: 'Version'
}
