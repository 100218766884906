import axios from 'axios'
import router from '@/router'
import vue from '/src/main.js'

const request = axios.create({
    timeout:  1000 * 60 * 5,
    baseURL: process.env.BASE_URL
});


// 添加请求拦截器
request.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    config.headers['Authorization'] = token
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    if (error.response.status === 412) {
        vue.$toast({
            title: this.$t('error'),
            message: error.response.data.msg,
            type: 'warning'
        })
    }
    if (error.response.status === 401) {
        return router.push({name: 'login'})
    }
    return Promise.reject(error);
});

export { request }
