export default {
    light: 'Light',
    dark: 'Dark',
    show_navigation: 'Show Navigation',
    hidden_navigation: 'Hidden Navigation',
    left_navigation: 'Left Navigation',
    right_navigation: 'Right Navigation',
    english: 'English',
    chinese: 'Chinese',
    setting: 'Settings',
    search: 'Search',
    warning: 'Warning',
    success: 'Success',
    danger: 'Danger'
}
