import Router from 'vue-router'
import Vue from 'vue'
import store from '@/store'
import i18n from '../i18n'
import layout_1 from '@/layout'

Vue.use(Router)

const routes = [
    // 机场模块 列表
    {
        path: '/',
        name: 'airportList',
        meta: {
            menu: false,
            title: 'menus.airport_list',
            group: 'menus.airport_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportList/airportList.vue')
    },
    {
        path: '/electromagnetic',
        name: 'electromagnetic',
        meta: {
            menu: false,
            title: 'menus.electromagnetic',
            group: 'menus.electromagnetic_field',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/electromagnetic/')
        // component: () => import('@/pages/electromagnetic/index_back_echarts.vue')

    },
    // 机场模块
    // {
    //     path: '/airport/plan',
    //     name: 'airport-plan',
    //     meta: {
    //         menu: true,
    //         title: 'menus.airport_site_plan',
    //         group: 'menus.airport_management'
    //     },
    //     component: () => import('@/pages/airportInfo/airportPlan/airportPlan.vue')
    // },
    // 修改机场
    {
        path: '/airport/edit/:id',
        name: 'airport-edit',
        props: true,
        meta: {
            menu: false,
            title: 'menus.airport_edit',
            group: 'menus.airport_management',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportAddEdit/airportAddEdit.vue')
    },
    // 添加机场
    {
        path: '/airport/add',
        name: 'airport-add',
        props: true,
        meta: {
            menu: false,
            title: 'menus.airport_add',
            group: 'menus.airport_management',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportAddEdit/airportAddEdit.vue')
    },
    // 机场节点
    {
        path: '/airport/node/:id/:selectNode',
        name: 'airport-node',
        props: true,
        meta: {
            menu: false,
            title: 'menus.airport_node',
            group: 'menus.airport_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportNode/index.vue')
    },
    // 机场edge
    {
        path: '/airport/edge/:id/:module_type',
        name: 'airport-edge',
        props: true,
        meta: {
            menu: false,
            title: 'menus.airport_edge',
            group: 'menus.airport_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportEdge/index.vue')
    },
    // 预览机场
    // {
    //     path: '/airport/preview',
    //     name: 'airportPreview',
    //     meta: {
    //         menu: false,
    //         title: 'menus.airport_preview',
    //         group: 'menus.airport_management'
    //     },
    //     component: () => import('@/pages/airportInfo/airportpreview/')
    // },
    // 飞机列表
    {
        path: '/aircraft/list',
        name: 'aircraftList',
        meta: {
            menu: false,
            title: 'menus.aircraft_list',
            group: 'menus.aircraft_management',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/aircraftInfo/aircraftList/aircraftList.vue')
    },
    // // 修改飞机
    // {
    //     path: '/aircraft/edit/:id',
    //     name: 'aircraft-edit',
    //     props: true,
    //     meta: {
    //         title: 'menus.aircraft_edit'
    //     },
    //     component: () => import('@/pages/aircraftInfo/aircraftAddEdit/aircraftAddEdit.vue')
    // },
    // // 添加飞机
    // {
    //     path: '/aircraft/add',
    //     name: 'aircraft-add',
    //     props: true,
    //     meta: {
    //         title: 'menus.aircraft_add'
    //     },
    //     component: () => import('@/pages/aircraftInfo/aircraftAddEdit/aircraftAddEdit.vue')
    // },
    // // 飞机类型模块
    // {
    //     path: '/aircraftType/list',
    //     name: 'aircraft-type',
    //     props: true,
    //     meta: {
    //         menu: true,
    //         title: 'menus.aircraft_type',
    //         group: 'menus.aircraft_management'
    //     },
    //     component: () => import('@/pages/aircaftType/aircaftTypeList.vue')
    // },
    // // 飞机厂商模块
    // {
    //     path: '/aircraftManufactures/list',
    //     name: 'aircraft-Manufactures',
    //     props: true,
    //     meta: {
    //         menu: true,
    //         title: 'menus.aircraft_manufactures',
    //         group: 'menus.aircraft_management'
    //     },
    //     component: () => import('@/pages/aircraftManufactures/index.vue')
    // },
    // 航班列表
    {
        path: '/flight/list',
        name: 'flightList',
        meta: {
            menu: true,
            title: 'menus.flight_list',
            group: 'menus.flight_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInfo/flightInfoList/flightInfoList.vue')
    },
    // 航班预览 甘特图
    {
        path: '/flight/preview',
        name: 'flightPreview',
        meta: {
            menu: false,
            title: 'menus.flight_preview',
            group: 'menus.flight_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInfo/flightpreview/')
    },
    // 航迹预览
    {
        path: '/flight_path/preview',
        name: 'flightPathPreview',
        meta: {
            menu: false,
            title: 'menus.flight_path_preview',
            group: 'menus.flight_path'
        },
        component: () => import('@/pages/flightPath/preview')
    },
    // // 航线列表
    // {
    //     path: '/airline',
    //     name: 'airline',
    //     meta: {
    //         menu: true,
    //         title: 'menus.airline_title',
    //         group: 'menus.airline_management'
    //     },
    //     component: () => import('@/pages/airLine/')
    // },
    // 节点类型列表
    // {
    //     path: '/nodetype',
    //     name: 'nodetype',
    //     meta: {
    //         menu: true,
    //         title: 'menus.nodetype_title',
    //         group: 'menus.airport_management'
    //     },
    //     component: () => import('@/pages/airportInfo/airportNodeType/')
    // },
    // 飞行情报区列表
    {
        path: '/flight_information',
        name: 'flightInformation',
        meta: {
            menu: false,
            title: 'menus.flight_information_list',
            group: 'menus.flight_information',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/flightInformationList/')
    },
        // 预览飞机情报区
        {
            path: '/flight_information_preview/:id',
            name: 'flightInformationPreview3d',
            meta: {
                menu: false,
                title: 'menus.flight_information_preview',
                group: 'menus.flight_information',
                use_layout: 'layout_main', // 使用的layout
            },
            component: () => import('@/pages/flightInformation/flightInformationList/preview')
        },
    // 管制区
    {
        path: '/control_region/:id',
        name: 'controlRegion',
        meta: {
            menu: false,
            title: 'menus.control_region',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/controlRegion/')
    },
    // 管制扇区
    {
        path: '/control_sector/:id/:info_id',
        name: 'controlSector',
        meta: {
            menu: false,
            title: 'menus.control_sector',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/sector/')
    },
    // 飞行情报区 扇区 管制扇区 预览
    {
        path: '/flight_information_preview/',
        name: 'flightInformationPreview',
        meta: {
            menu: false,
            title: 'menus.flight_information_preview',
            group: 'menus.flight_information',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/preview/')
    },
    // 管制区预览
    {
        path: '/controlRegion/preview/:id',
        name: 'controlRegionPreview',
        meta: {
            menu: false,
            title: 'menus.control_region_preview',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/controlRegion/controlpreview.vue')
    },
    // 管制扇区预览
    {
        path: '/control_sector/preview/:id/:info_id',
        name: 'controlSectorPreview',
        meta: {
            menu: false,
            title: 'menus.control_sector_preview',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/flightInformation/sector/sectorprevier.vue')
    },
    // cesium 测试页面
    {
        path: '/explame',
        name: 'explame',
        meta: {
            menu: false,
            title: 'menus.control_sector_preview',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/explame/')
    },
    // 扇区划分算法参数界面
    {
        path:"/sectorplanning",
        name:"sectorPlanning",
        meta:{
            menu: false,
            title: 'menus.sector_planning',
            group: 'menus.flight_information',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component:()=>import('@/pages/flightInformation/sectorPlanning')
    },
    // 机场cesium
    {
        path: '/airport_cesium/:id',
        name: 'airportCesium',
        meta: {
            menu: false,
            title: 'menus.airport_cesium',
            group: 'menus.airport_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/airportCesium/')
    },
    // 飞机停靠预览
    {
        path: '/aircraft_docking_preview/:start_time/:end_time/:airport_id',
        name: 'aircraftDockingPreview',
        meta: {
            menu: false,
            title: 'menus.aircraft_docking_preview',
            group: 'menus.airport_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/airportInfo/aircraftDockingPreview/')
    },

    // 保障车信息列表
    {
        path:'/airport_security/protection_vehicle_info',
        name:'protectionVehicleInfo',
        meta:{
            menu: true,
            title: 'menus.protection_vehicle_info_list',
            group: 'menus.airport_security',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component:()=>import('@/pages/airportSecurity/protectionVehicle/vehicleInfoList')
    },
    // 保障车类型列表
    {
        path:'/airport_security/protection_vehicle_type',
        name:'protectionVehicleType',
        meta:{
            menu: true,
            title: 'menus.protection_vehicle_type_list',
            group: 'menus.airport_security',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component:()=>import('@/pages/airportSecurity/protectionVehicle/vehicleTypeList')
    },
    // 测试Demo 保障车预览
    // {
    //     path:'/airport_security/vehicle_preview',
    //     name:'vehiclePreview',
    //     meta:{
    //         menu: true,
    //         title:'menus.vehicle_preview',
    //         group: 'menus.airport_security'
    //     },
    //     component:()=>import('@/pages/airportSecurity/protectionVehicle/vehiclePreview')
    // },
    // 保障车停靠预览
    {
        path: '/protection_vehicle_preview/:start_time/:end_time',
        name: 'protectionVehiclePreview',
        meta: {
            menu: false,
            title: 'menus.protection_vehicle_preview',
            group: 'menus.airport_security',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/airportSecurity/protectionVehicle/preview')
    },
    {
        path:'/airport_security/airport_security_scheduler',
        name:'AirportSecurityScheduler',
        meta:{
            menu: true,
            title:'menus.airport_security_scheduler',
            group: 'menus.airport_security',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component:()=>import('@/pages/airportSecurity/protectionVehicle/airportSecurityScheduler')
    },
        // 机场保障排班表预览
        {
            path: '/airport_security_scheduler/preview',
            name: 'airportSecuritySCHPreview',
            meta: {
                menu: false,
                title: 'menus.airport_security_scheduler_preview',
                group: 'menus.airport_security',
                use_layout: layout_1, // 使用的layout
            },
            component: () => import('@/pages/airportSecurity/protectionVehicle/airportSecuritySCHPre')
        },
    // 航线冲突模块
    {
        path:'/flight_conflict',
        name:'flight_conflict',
        meta:{
            menu: false,
            title:'menus.flight_conflict',
            group: 'menus.flight_conflict',
            use_layout: 'layout_main', // 使用的layout
        },
        component:()=>import('@/pages/flight_conflict')
    },
    // 登录
    {
        path:'/login',
        name:'login',
        component:()=>import('@/pages/auth/login/index.vue')
    },
    // 修改密码
    {
        path:'/edit_password',
        name:'edit_password',
        meta:{
            menu: false,
            title:'menus.edit_password',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component:()=>import('@/pages/auth/editpassword/index.vue')
    },
    // 用户列表
    {
        path:'/user_list',
        name:'user_list',
        meta:{
            menu: false,
            title:'menus.user_list',
            group: 'menus.system_functions',
            use_layout: 'layout_main', // 使用的layout
        },
        component:()=>import('@/pages/auth/userList/index.vue')
    },
    // 进离场程序优化 航路哟花
    {
        path: '/air_route',
        name: 'air_route',
        meta: {
            menu: false,
            title: 'menus.air_route',
            group: 'menus.air_route_optimization',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/air_route')
    },
    // 系统管理：用户管理
    {
        path:'/system/user_management',
        name:'user_management',
        meta: {
            menu: false,
            title: 'menus.system_user_management',
            group: 'menus.system_management',
            use_layout: 'layout_auxiliary', // 使用的layout
        },
        component: () => import('@/pages/system/userManagement/index')
    },
    // 系统管理：角色权限
    {
        path:'/system/role_permissions',
        name:'role_permissions',
        meta: {
            menu: false,
            title: 'menus.system_role_permissions',
            group: 'menus.system_management',
            use_layout: 'layout_main', // 使用的layout
        },
        component: () => import('@/pages/system/rolePermissions/index')
    },
    // 系统管理：日志管理
    {
        path:'/system/log_management',
        name:'log_management',
        meta: {
            menu: false,
            title: 'menus.system_log_management',
            group: 'menus.system_management',
            use_layout: 'layout_main', // 使用的layout
        },
        component: ()=> import('@/pages/system/logManagement/index')
    },
    // 自由航路
    {
        path: '/free_route/index',
        name: 'freeRouteIndex',
        meta: {
            menu: false,
            title: 'menus.free_route_index',
            group: 'menus.free_route',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/free_route/')

    },
    {
        path: '/free_route/index',
        name: 'freeRouteIndex',
        meta: {
            menu: false,
            title: 'menus.free_route_index',
            group: 'menus.free_route',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/free_route/')

    },
    {
        path: '/edit_personal_info',
        name: 'editPersonalInfo',
        meta: {
            menu: false,
            title: 'menus.edit_personal_info',
            group: 'menus.edit_personal_info',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/auth/editPersonalInfo/index.vue')

    },
    {
        path: '/algorithm/list',
        name: 'algorithmList',
        meta: {
            menu: false,
            title: 'menus.edit_personal_info', // TODO not change title
            group: 'menus.edit_personal_info',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/algorithm/list/')

    },
    {
        path: '/algorithm/create',
        name: 'algorithmCreate',
        meta: {
            menu: false,
            title: 'menus.edit_personal_info', // TODO not change title
            group: 'menus.edit_personal_info',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/algorithm/create/')

    },
    {
        path: '/algorithm/:id/edit',
        name: 'algorithmEdit',
        props: true,
        meta: {
            menu: false,
            title: 'menus.edit_personal_info', // TODO not change title
            group: 'menus.edit_personal_info',
            use_layout: 'layout_main'
        },
        component: () => import('@/pages/algorithm/create/')

    },
    {
        path: '/version',
        name: 'Version',
        meta: {
            menu: false,
            title: 'menus.version'
        },
        component: () => import('@/pages/version/')
    }
]

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    if (token) {
        // document.title = i18n.t(to.meta.title)
        document.title = '空中交通管理仿真和演示平台'
        to.meta.menu && store.commit('push_top_nav', to)
    }else {

        if (to.name !== 'login') {
            next({
                name: 'login',
                query: {
                    referent: to.name
                }
            })
        }
    }
    next()
})

export default router




// 开始处理导航
const await_handle_menu_list = routes.filter(x => {
    return x.meta?.menu
})

const _menu_list = {}
const menu_list = []

for (let i of await_handle_menu_list) {
    if (i.meta.group in _menu_list) {
        _menu_list[i.meta.group].push(i)
    } else {
        _menu_list[i.meta.group] = []
        _menu_list[i.meta.group].push(i)
    }
}


for (let i in _menu_list) {
    if (i == 'undefined') {
        menu_list.push(..._menu_list[i])
    } else {
        menu_list.push({
            meta: {
                title: i,
            },
            children: [..._menu_list[i]]
        })
    }
}

export { menu_list }
