import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import store from '@/store'
import Layout from '@/layout/'
import LayoutMain from '@/layout/main'
import i18n from '@/i18n'
import DatetimePicker from 'vuetify-datetime-picker'
import {setTheme, setDark} from '@/utils/setTheme'
import VuetifyjsAuxiliary from 'vuetifyjs-auxiliary'
import 'vuetifyjs-auxiliary/lib/vuetifyjs-auxiliary.css'
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/font.css'
import {mixins} from "./utils";
import {get_secret_key} from "./api/cos";

const Cesium = require('cesium/Cesium');
const widgets = require('cesium/Widgets/widgets.css');
const COS = require('cos-js-sdk-v5');

Vue.config.production = false

// 注册布局组件
Vue.component('layout_auxiliary', Layout)
Vue.component('layout_main', LayoutMain)


// 绑定在Vue实例上
Vue.prototype.$set_theme = setTheme
Vue.prototype.$set_dark = setDark

Vue.config.productionTip = false
Vue.prototype.Cesium = Cesium
window.Cesium = Cesium
window.netcdfjs = netcdfjs
Vue.prototype.widgets = widgets
Vue.prototype.Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMzNhNzU4Mi04Y2FhLTQ4N2ItOWM1Zi1mZDYwOWQzNmNmZWQiLCJpZCI6NTI4MTIsImlhdCI6MTYyNDMzMjU2Nn0.4DuA5ogy5PqADZq8quVJF10equylklUzx2ok1Eot5aI';

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
Vue.use(DatetimePicker)

Vue.use(VuetifyjsAuxiliary)
Vue.use(VuejsDialog);

Vue.mixin(mixins)

window.cos = new COS({
    // 必选参数
    getAuthorization: function (options, callback) {
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048

        get_secret_key().then(res => {
            const data = res.data
            callback({
                TmpSecretId: data.credentials.tmpSecretId,
                TmpSecretKey: data.credentials.tmpSecretKey,
                SecurityToken: data.credentials.sessionToken,
                // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
            });
        })
    }
});

export default new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App),
    mounted() {
        // remove loading animation
        const loading_dom = document.getElementById('first_loading');
        document.body.removeChild(loading_dom)
        // 初始化主题
        // this.$set_theme(localStorage.getItem('theme_index'))
        this.$set_dark(localStorage.getItem('dark_mode'))
        this.$store.commit('set_nav_position', localStorage.getItem('nav_position') || 'left')
        this.$store.commit('set_nav_show', localStorage.getItem('nav_show') || '1')
        this.$set_theme(5)


        const lang = localStorage.getItem('lang')
        if (lang) {
            this.$i18n.locale = lang
        }
        // this.$set_dark(1)
        // 初始化用户信息
        let user_info = localStorage.getItem('user_info')
        if (user_info) {
            user_info = JSON.parse(user_info)
            this.$store.commit('set_user_info', user_info)
        }
    },
}).$mount('#app')
