export default {
    airport_name: '机场名称',
    place: '地点',
    area: '面积',
    boarding_gate: '登机口',
    operation: '操作',
    preview: '实时预览',
    edit: '编辑信息',
    add_airport: '添加机场',
    search:'机场名称',
    Name:'机场名称',
    Area: "机场面积",
    Lat: "纬度",
    Lng: "经度",
    Apron_number: "停机坪数量",
    Runway_number: "跑道数量",
    Country: "国际",
    Province: "省份",
    City: "城市",
    Height: "机场长度",
    Weight: "机场宽度",
    Status: "机场状态",
    Action:"操作",
    Place:"地区",
    Submit:"提交",
    Reset:"重置",
    title: '机场列表',
    Search_Title:"机场节点名称",
    airportNode:"机场节点列表",
    securityNode:"机场保障车节点列表",
    node_type: "节点类型",
    node_name: "节点名称",
    transverse_coordinate: "横坐标",
    longitudinal_coordinate: "纵坐标",
    lat: "纬度",
    lng: "经度",
    match_model: "容纳机型",
    remote_stand: "是否为远机位",
    airport_id: "关联机场ID",
    NodeTitle:"机场节点组名",
    securityNodeTitle:"保障车节点组名",
    add_Node:"添加节点",
    edit_Node:"编辑节点",
    selectNode:"选择节点",
    name:"名称",
    type:"节点类型",
    group:"组名",
    order:"排序",
    road_type:"道路类型",
    attach:"关联",
    start_time:"开始时间",
    end_time:"结束时间",
    start_date:"开始日期",
    end_date:"结束日期",
    preview_airport:"预览",
    preview_car:"预览保障车",
    airport_node:"机场节点",
    security_node:"机场保障模块节点",
    node_data:"节点",

    edge:"链接",
    start_id:"起始节点",
    end_id:"终止节点",
    is_two_way:"是否为双向道",
    yes:'是',
    no:'否',
    edit_edge:"编辑链接",
    add_edge:"添加链接",
    airportEdge:"机场链接列表",
    airport_edge:'机场链接',


    change_password:'修改密码',
    log_out:'退出登录',
    my:'个人中心',
    edit_personal_info:"修改个人信息",

    flight_information_area:'飞行情报区',
    approach_and_departure_route_optimization:'进离场航路优化',
    route_conflict_detection:'航线冲突检测',
    visualization:'电磁场三维可视化',
    freeways:'自由航路',
    user_management:'用户管理',
    role_management:'角色管理',
    log_management:'日志管理',

    tarmac:'停机坪',
    runway:'跑道',

    flight_management:'航班管理',
    airport_security:'机场保障',
    details:'机位占用详情',
    total_time:"滑行总时间",
    alert_text:"该机位在当前时间段内没有被占用",
    conflict:"冲突机位",
    No_conflict:"暂无冲突",
    will:'将在',
    gate_position:"停机位",
    be_conflict:"发生冲突",

    Node:"节点",
    Edge:"链接",
    Enter:"进入",

    edit_success_edge:"修改链接成功",
    add_success_edge:"添加链接成功",
    delete_edge:"确认删除链接吗",
    delete_airport:"确认删除机场吗",
    error_id:'未获取到机场ID',
    select_time_range:"请选择时间范围",
    edit_success_node:"修改节点成功",
    add_success_node:"添加节点成功",
    delete_node:"确认删除该节点吗",
    edit_success_node_type:"修改节点类型成功",
    add_success_node_type:"添加节点类型成功",
    delete_node_type:"确认删除该节点类型吗",

    aircraft_list: '飞机列表',

    airport_position: '机场位置',

    current_airport_information: '机场信息',
    EnName: '英文名',

    upload_img_hint: '机场图片,Chrome 和 FireFox 支持拖拽图片到此区域上传',
    upload_img_once:"每次仅支持上传一张图片",
    flight_gantt: '航班甘特图',
    ICAO: 'ICAO'
}
