export default{
    details:"Current Region Details",
    current_region:"current region",
    weather:"weather",
    temperature:"temperature",
    wind_direction:"wind",
    wind_power:"wind power",
    air_quality:"air quality",
    airport_list:"airport list",
    recalculate:"recalculate",
    standard_procedure:"standard procedure",
    approach:"Approach",
    departure:"Departure",
    Optimized_program:"Optimized program",
    Other_Settings:"Other Settings",
    Free_Roaming_View:"Automatic viewing angle",
    display_the_airspace:"display the airspace",
    input_parameter:"input parameter",
    Maximum_climbing_angle:"Maximum climbing angle",
    Minimum_climbing_angle:"Minimum climbing angle",
    maximum_drop_angle:"maximum drop angle",
    Minimum_drop_angle:"Minimum drop angle",
    Minimum_horizontal_interval:"Minimum horizontal interval",
    Minimum_vertical_interval:"Minimum vertical interval",
    point:"minimum interval between aggregation (divergent) points",
    SID:"The effective length of SID",
    STAR:"The effective length of Star",
    approach_tur:"Maximum Angle of approach turn",
    departure_tur:"Maximum departure turn Angle",
    Route_weighted:"Route weighted length weight",
    Net_length:"Net length weight of route",
    grid:"The length of the grid",
    expansion:"Route expansion mode",
    max_annealing_temperature:"Maximum annealing temperature",
    min_annealing_temperature:"Minimum annealing temperature",
    Cooling_rate:"Cooling rate",
    iterations:"The number of iterations",
    replacing_matrix:"The probability of replacing the matrix",
    substitution:"The probability of substitution",
    thredetourshold:"Route detour threshold",
    Arc_chord:"Arc-chord ratio threshold",
    degree: 'degree',
    nm: 'nm',
    ft: 'ft',
    show_obstacle: 'Show Obstacle',
    show_aircraft: 'Show Aircraft',
    loading:"loading",
    show_tracks: 'Show Tracks'

}
