<template>
    <div class="container1">
        <div @click="is_open = true" v-if="!is_open" fluid :elevation="24" class="setting">
            <v-icon color="top">mdi-cog</v-icon>
        </div>
        <v-scroll-x-reverse-transition>
        <v-card  transition="" @mouseleave="is_open=false" style="opacity: .8" v-if="is_open">
            <v-card-title>{{$t('setting.setting')}}</v-card-title>
            <v-row>
                <v-col @click="set_theme(k)" :key="i.color" v-for="(i, k) in theme_list" class="text-center" cols="3">
                    <v-avatar
                            :color="i.color"
                            size="26"
                            class="color__item"
                    >
                    </v-avatar>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-radio-group
                                v-model="dark_mode"
                                row
                        >
                            <v-radio
                                    :label="$t('setting.light')"
                                    value="2"
                            ></v-radio>
                            <v-radio
                                    :label="$t('setting.dark')"
                                    value="1"
                            ></v-radio>
                        </v-radio-group>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-radio-group
                                @change="changeNavShow"
                                v-model="nav_show"
                                row
                        >
                            <v-radio
                                    :label="$t('setting.show_navigation')"
                                    value="1"
                            ></v-radio>
                            <v-radio
                                    :label="$t('setting.hidden_navigation')"
                                    value="0"
                            ></v-radio>
                        </v-radio-group>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-radio-group
                                @change="changeNavPosition"
                                v-model="nav_position"
                                row
                        >
                            <v-radio
                                    :label="$t('setting.left_navigation')"
                                    value="left"
                            ></v-radio>
                            <v-radio
                                    :label="$t('setting.right_navigation')"
                                    value="right"
                            ></v-radio>
                        </v-radio-group>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-radio-group
                                @change="changeLang"
                                v-model="lang"
                                row
                        >
                            <v-radio
                                    :label="$t('setting.chinese')"
                                    value="zh"
                            ></v-radio>
                            <v-radio
                                    :label="$t('setting.english')"
                                    value="en"
                            ></v-radio>
                        </v-radio-group>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
        </v-scroll-x-reverse-transition>
    </div>
</template>
<script>
    import { theme_list } from "../../plugins/theme";

    export default {
        name: 'setting',
        data: () => ({
            is_open: false,
            dark_mode: '1',
            nav_position: 'left',
            show: null,
            theme_list,
            lang: ''
        }),
        methods: {
            changeLang(value) {
                localStorage.setItem('lang', value)
                this.$i18n.locale = value
            },
            changeNavShow(value) {
                this.$store.commit('set_nav_show', value)
            },
            changeNavPosition(value) {
                this.$store.commit('set_nav_position', value)
            },
            set_theme(k) {
                this.$set_theme(k)
            },

        },
        watch: {
            'dark_mode'(value) {
                this.$set_dark(value)
            },
        },
        mounted() {
            this.dark_mode= localStorage.getItem('dark_mode')
            this.nav_position= localStorage.getItem('nav_position')
            this.nav_show= localStorage.getItem('nav_show')
            this.lang = localStorage.getItem('lang') || 'ch'
            this.changeLang(this.lang)
        }
    }
</script>
<style lang="stylus" scoped>
    .container1
        position fixed
        top 80px
        right 0px
        z-index 99
        width 300px
        .color__item
            cursor pointer
        .content
            margin-top 50px
            height 100%
            background blue
        .setting
            width 40px
            z-index 99
            position absolute
            right 0px
            background var(--v-primary-base)
            border-radius 10px 0 0 10px
            padding 10px
            cursor pointer
            opacity .4
            user-select none
            transition all ease .1s
            &:hover
                opacity: 1
                transition all ease .1s
</style>
