export default {
    totalTitle:"算法参数输入界面",
    firstTitle:"空域及算法",
    secondTitle:"中间过程参数",
    thirdTitle:"工作负荷计算相关参数",
    fourthTitle:"约束条件相关参数",
    fifthTitle:"算法相关参数",
    algorithmSelection: "算法选择",
    airspaceSelection: "空域选择",
    parameterInput: "系列三维点坐标",
    flightPathNumber: "对应航迹数据编号",
    grigSizeLength: "网格尺寸(长)",
    grigSizeWidth: "网格尺寸(宽)",
    grigSizeHeight: "网格尺寸(高)",
    sectorNumber: "划分扇区个数",
    heightLayer: "划分高度层",
    VoronoiNumber: "平面Voronoi图个数",
    flightFlowTime: "监视一分钟飞行流所需时间",
    resolveConflictTime: "解决一个飞机冲突所需时间",
    controlTransferTime: "进行一次管制移交所需的时间",
    maxLoad: "单个扇区最大工作负荷",
    minStayTime: "扇区内最短停留时间",
    shortestDistance: "冲突点距离扇区边界的最短距离",
    allowReenter: "允许的再入事件百分比",
    allowShortCross: "允许的短暂穿越时间百分比",
    AllowSectorImbalance: "允许的扇区不平衡度",
    evolutionaryAlgebra: "进化代数",
    populationSize: "种群数大小",
    crossProbability: "交叉概率",
    variationProbability: "变异概率",
    initCombinationCrossProbability: "初始组合交叉概率",
    SBXdistributionindex: "SBX分布指数",
    submit:"确定",
    reset:"重置"
}