<template>
    <div @mousemove="mousemove" @mouseup="clickend">
        <v-app class="bg">
            <v-navigation-drawer :value="nav_show" color="secondary" :right="nav_right" :clipped="true" app>
<!--                 用于显示当前机场信息的card-->
                <v-card color="secondary">
                    <v-card-title>{{$t('airport.current_airport_information')}}
                    </v-card-title>
                    <v-container fluid>
                        <img
                                style="width: 230px"
                                :src=" airport_info.img || 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/assets/airport_common.jpg'"
                        />
                    </v-container>
                    <v-container fluid>
                        <p class="caption prohibit_user_behavior">
                            {{$t('airport.airport_name')}}: {{airport_info.name}}
                        </p>
                        <p class="caption prohibit_user_behavior">
                            {{$t('airport.airport_position')}}: {{airport_info.country}}
                        </p>
                        <p class="caption prohibit_user_behavior">
                            {{ $t('airport.runway') }}: <span class="font-number" style="font-size: 18px">{{airport_info.runway_number}}</span>
                        </p>
                        <p class="caption prohibit_user_behavior">
                            {{ $t('airport.tarmac') }}: <span class="font-number" style="font-size: 18px">{{airport_info.apron_number}}</span>
                        </p>
                        <p class="caption prohibit_user_behavior">
                            {{$t('airport.area')}}: <span class="font-number" style="font-size: 18px">{{airport_info.area}}</span>㎡
                        </p>
                    </v-container>
                </v-card>
                <template v-for="i of menu_list">
                    <v-list-group :value="i.meta.title === $route.meta.group" v-if="i.children" no-action>
                        <template v-slot:activator>
                            <v-list-item-title>{{$t(i.meta.title)}}</v-list-item-title>
                        </template>
                        <v-list-item :key="ii.path" :to="ii.path" v-for="ii of i.children" link>
                            <v-list-item-title>{{$t(ii.meta.title)}}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list v-else>
                        <v-list-item :to="i.path" link>
                            <v-list-item-title>{{$t(i.meta.title)}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </template>

            </v-navigation-drawer>
            <v-app-bar color="bg" :clipped-right="true" :clipped-left="true" app >
                <custom-title></custom-title>
                <v-spacer></v-spacer>
                <i @click="open_preview_airport" style="font-size: 20px; cursor: pointer; margin-right: 10px"
                   class="airport icon-preview text_color--text"></i>
                <v-icon
                        class="text_color--text text--lighten-1"
                        @click="requestFullScreen($refs.screen)"
                        left
                >
                    mdi-fullscreen
                </v-icon>
                <div style="margin-right: 10px">
                    <i @click="set_lang('zh')" v-if="$i18n.locale==='en'" style="font-size: 20px; cursor: pointer;"
                       class="airport icon-zhongwen text_color--text"></i>
                    <i @click="set_lang('en')" v-else style="font-size: 20px; cursor: pointer;"
                       class="airport icon-yingwen text_color--text"></i>
                </div>
                <div style="margin-right: 20px">

                    <i @click="set_dark_mode('0')" v-if="$store.state.is_dark"
                       style="font-size: 22px; cursor: pointer;" class="airport icon-taiyang text_color--text"></i>
                    <i @click="set_dark_mode('1')" v-else style="font-size: 22px; cursor: pointer;"
                       class="airport icon-yueliang1 text_color--text"></i>
                </div>
                <div class="text-center">
                    <v-menu nudge-left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab text v-on="on" v-bind="attrs">
                                <v-badge
                                        bordered
                                        v-bind="attrs"
                                        v-on="on"
                                        bottom
                                        color="green"
                                        dot
                                        offset-x="10"
                                        offset-y="10"
                                >
                                    <v-avatar>
                                        <img
                                                :src=" $store.state.user_info.userinfo.avatar || 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/assets/avatar.jpeg'"
                                                alt="adminUser"
                                        >
                                    </v-avatar>
                                </v-badge>
                            </v-btn>
                        </template>
                        <v-list color="secondary">
                            <v-list-item :to="{ name: 'editPersonalInfo'}">
                                <v-list-item-title>{{$t('airport.my')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="open_edit_password">
                                <v-list-item-title>{{$t('airport.change_password')}}</v-list-item-title>
                            </v-list-item>
                            <!--                               <v-list-item :to="{ name: 'editPersonalInfo',params:{id:user_id}}">-->
                            <!--                                <v-list-item-title>{{$t('airport.edit_personal_info')}}</v-list-item-title>-->
                            <!--                            </v-list-item>-->
                            <v-list-item :to="{ name: 'login'}">
                                <v-list-item-title>{{$t('airport.log_out')}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-app-bar>
            <!-- 根据应用组件来调整你的内容 -->
            <v-main>
                <!-- 给应用提供合适的间距 -->
                <v-card class="top_nav">
                    <div :key="`nav_top${item.path}`"
                         :ref="item.name"
                         class="top_nav__item overline"
                         v-for="(item, index) in top_nav_data"
                         @mousedown.self="clickstart($event, item, index)"
                         @click.self="click_tag(item)"
                         :elevation="$route.path == item.path ? 6 : 3"
                         :style="$route.path == item.path ? `background: var(--v-secondary-darken4)` : 'background: var(--v-secondary-darken3)'">
                        {{$t(item.meta.title)}}
                        <div>
                            <v-icon @click.stop="close_top_nav(index, item)"
                                    style="position: absolute; right: 4px; top: 7px"
                                    color="#ccc" small>mdi-close
                            </v-icon>
                        </div>
                    </div>
                </v-card>

                <v-container class="bg" fluid>
                    <v-scale-transition>
                        <v-card ref="screen" class="content" v-if="show">
                            <slot></slot>
                            <!--                            TODO 防止边界溢出 (以后需要优化css)-->
                            <div style="clear: both;margin-bottom: 20px"></div>
                        </v-card>
                    </v-scale-transition>

                </v-container>
            </v-main>
            <v-dialog width="800px" v-model="edit_password_dialog">
                <EditPassword @close_dialog="close_dialog" ref="edit_password"></EditPassword>
            </v-dialog>
        </v-app>


        <v-dialog
                v-model="dialog"
                width="800px"
        >
            <v-card color="secondary">
                <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="basil"
                        grow
                >
                    <v-tab
                            v-for="item in items"
                            :key="item.text"
                            @change="changeTab"
                    >
                        {{ item.text }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card color="secondary" flat>
                            <v-card-text>
                                <v-container class="pd-30">
                                    <v-row>
                                        <v-col :cols="6">
                                            <v-datetime-picker :label="$t('airport.start_time')" time-format="HH:mm"
                                                               v-model="startTime">

                                            </v-datetime-picker>
                                        </v-col>
                                        <v-col :cols="6">
                                            <v-datetime-picker :label="$t('airport.end_time')" time-format="HH:mm"
                                                               v-model="endTime">

                                            </v-datetime-picker>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card color="secondary" flat>
                            <v-card-text>
                                <v-container class="pd-30">
                                    <v-row>
                                        <v-col :cols="6">
                                            <v-datetime-picker :label="$t('airport.start_time')" time-format="HH:mm"
                                                               v-model="startTime">

                                            </v-datetime-picker>
                                        </v-col>
                                        <v-col :cols="6">
                                            <v-datetime-picker :label="$t('airport.end_time')" time-format="HH:mm"
                                                               v-model="endTime">

                                            </v-datetime-picker>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" :disabled="!valid" small @click="validate">
                        {{$t('confirm')}}
                    </v-btn
                    >
                    <v-btn color="secondary2" small @click="dialog = false"
                    > {{$t('cancel')}}
                    </v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import Settings from './components/Settings'
    import {menu_list} from '@/router'
    import {requestFullScreen} from "../utils";
    import EditPassword from '@/pages/auth/editpassword'
    import CustomTitle from "../components/CustomTitle";
    import {get_info} from "../api/airport";

    let moment = require('moment')


    export default {
        name: 'layout',
        data: () => ({
            // nav_right: false
            dialog: false,
            valid: true,
            startTime: '',
            endTime: '',
            airport_id: '',
            airport_info: {
                apron_number: 'loading',
                area: 'loading',
                city: 'loading',
                country: 'loading',
                create_time: 'loading',
                delete_time: 'loading',
                height: 'loading',
                id: 'loading',
                lat: 'loading',
                lng: 'loading',
                name: 'loading',
                province: 'loading',
                runway_number: 'loading',
                status: 'loading',
                update_time: 'loading',
                width: 'loading',
                img: ''
            },
            menu_list,
            show: false,
            edit_password_dialog: false,
            nav_position: [],
            click_lang_time: null,
            long_click: false,
            move_dom: null,
            tab: null,
            left_margin: 0,
            mouse_position: 0,
            nav_index: null,
            user_id: null,
            isAirport: true,
            current_position: null
        }),
        methods: {
            open_preview_airport() {
                this.dialog = true
            },
            validate() {
                this.dialog_airport_id = this.$store.state.select_air_port_id || this.$route.query.airport_id
                if (!this.dialog_airport_id) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t("airport.error_id"),
                        type: 'warning'
                    })
                }
                if (this.startTime && this.endTime) {
                    if (this.isAirport) {
                        this.$router.push({
                            name: "aircraftDockingPreview",
                            params: {
                                start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                                end_time: moment(this.endTime).format('YYYY-MM-DD HH:mm'),
                                airport_id: this.dialog_airport_id
                            }
                        })
                    } else {
                        this.$router.push({
                            name: "protectionVehiclePreview",
                            params: {
                                start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                                end_time: moment(this.endTime).format('YYYY-MM-DD HH:mm'),
                                airport_id: this.dialog_airport_id
                            }
                        })
                    }
                } else {
                    this.$toast({
                        title: this.$t('error'),
                        message: this.$t("airport.select_time_range"),
                        type: 'waring'
                    })
                }
            },
            changeTab() {
                this.isAirport = !this.isAirport
            },
            close_dialog() {
                this.edit_password_dialog = false
            },
            open_edit_password() {
                this.edit_password_dialog = true
                this.$nextTick(() => {
                    this.$refs.edit_password.reset()
                })
            },
            set_lang(value) {
                localStorage.setItem('lang', value)
                this.$i18n.locale = value
            },
            set_dark_mode(value) {
                this.$set_dark(value)
            },
            mousemove(e) {
                if (this.long_click) {
                    if (this.move_dom) {
                        this.move_dom.style.left = e.screenX - this.left_margin - this.mouse_position - (this.nav_index * this.move_dom.offsetWidth) + 'px'
                        this.move_dom.style.zIndex = '99999'
                        this.move_dom.style.boxShadow = '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset'
                        this.current_position = e.screenX - this.mouse_position
                    }
                }
            },
            click_tag(item) {
                this.to_menu(item)
            },
            clickend() {
                if (this.click_lang_time) {
                    clearInterval(this.click_lang_time)
                }
                if (this.long_click) {
                    if (this.move_dom) {
                        this.move_dom.style.left = 'auto'
                        this.move_dom.style.zIndex = '0'
                        this.move_dom.style.boxShadow = 'none'
                    }
                    if (this.nav_index !== null) {
                        let nav_item_position = 0
                        for (let i in this.nav_position) {
                            if (this.nav_position[i] < this.current_position) {
                                nav_item_position = i
                            }
                        }
                        this.$store.commit('reset_nav_position', {
                            new_position: nav_item_position,
                            origin_position: this.nav_index
                        })
                    }
                }
                this.click_lang_time = null
                this.long_click = false
                this.move_dom = null
                this.current_position = null
                this.nav_index = null
                this.mouse_position = 0
            },
            clickstart(e, item, nav_index) {
                e.preventDefault()
                this.long_click = false
                const start_time = (new Date()).getTime()

                this.click_lang_time = setInterval(() => {
                    if ((new Date()).getTime() - start_time > 200) {
                        this.move_dom = this.$refs[item.name][0]
                        if (!this.move_dom) {
                            this.clickend()
                        }
                        this.mouse_position = e.offsetX
                        this.long_click = true
                        this.nav_index = nav_index
                    }
                }, 100)
            },
            requestFullScreen(el) {
                requestFullScreen(el.$el)
            },
            close_top_nav(index, data) {
                if (this.top_nav_data.length <= 1) {
                    return
                }
                if (this.$route.path == data.path) {
                    if (index == 0) {
                        this.$router.push({
                            path: this.top_nav_data[1].path
                        })
                    } else {
                        this.$router.push({
                            path: this.top_nav_data[index - 1].path
                        })
                    }
                }
                this.$store.commit('close_top_nav', index)
            },
            to_menu(data) {

                if (this.$route.path == data.path) return;
                this.$router.push({path: data.path})
            },
            to_home() {
                this.$store.commit('set_air_port_id', null)
                this.$router.push({path: '/'})
            }
        },
        mounted() {
            this.user_id = JSON.parse(localStorage.getItem('user_info')).user_id
            // 在这里收集机场的ID
            this.airport_id = this.$route.query.airport_id || this.$store.state.select_air_port_id
            if (this.airport_id) {
                get_info(this.airport_id).then(res => {
                    this.airport_info = res
                })
            }

            this.show = true
            this.$nextTick(() => {
                const nav_position = []
                for (let i of this.top_nav_data) {
                    nav_position.push(this.getLeft(this.$refs[i.name][0]))
                }
                this.left_margin = nav_position[0]
                this.nav_position = nav_position
            })
        },
        computed: {
            nav_right() {
                return this.$store.state.nav_position === 'right'
            },
            nav_show() {
                return this.$store.state.nav_show
            },
            top_nav_data() {
                return this.$store.state.top_nav_data
            },

            items() {
                return [
                    {
                        text: this.$t("airport.preview_airport")
                    },
                    {
                        text: this.$t("airport.preview_car")
                    }
                ]
            },
        },
        components: {
            CustomTitle,
            EditPassword,
        },
    }
</script>
<style lang="stylus" scoped>
    .wrap_content
        background var(--v-secondary-base)

    .content
        background var(--v-secondary-base)
        min-height calc(100vh - 120px)

    .top_nav
        background: var(--v-secondary-darken1);
        padding: 0px;
        display: flex

        &__item
            width: 150px
            cursor: pointer
            user-select: none
            color: #ccc
            padding: 0 17px 0 3px
            position: relative
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
            margin-right: 3px

</style>
