export default {
    light: '浅色',
    dark: '深色',
    show_navigation: '显示导航',
    hidden_navigation: '隐藏导航',
    left_navigation: '左侧导航',
    right_navigation: '右侧导航',
    english: '英文',
    chinese: '中文',
    setting: '设置',
    search: '筛选',
    warning: '警告',
    success: '成功',
    danger: '危险'
}
