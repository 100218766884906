export default {
    title: '节点类型列表',
    edit: '修改',
    add: '添加',
    name: '类型名称',
    update_time: '更新时间',
    create_time: '创建时间',
    Action: '操作',
    edit_action: '修改节点类型信息',
    add_action: '添加节点类型信息'

}
