
const mixins = {
    methods: {

        // rgb to hex
        rgbToHex(r, g, b)
        {
            let hex = ((r<<16) | (g<<8) | b).toString(16);
            return "#" + new Array(Math.abs(hex.length-7)).join("0") + hex;
        },

        // hex to rgb
        hexToRgb(hex)
        {
            let rgb = [];
            for(let i=1; i<7; i+=2){
                rgb.push(parseInt("0x" + hex.slice(i,i+2)));
            }
            return rgb;
        },

        // compute gradient color
        gradient (startColor,endColor,step, rgb_mode = true)
        {
            //将hex转换为rgb
            var sColor = this.hexToRgb(startColor),
                eColor = this.hexToRgb(endColor);

            //计算R\G\B每一步的差值
            var rStep = (eColor[0] - sColor[0]) / step;
            let gStep = (eColor[1] - sColor[1]) / step;
            let bStep = (eColor[2] - sColor[2]) / step;

            var gradientColorArr = [];
            for(var i=0;i<step;i++){
                //计算每一步的hex值
                if (rgb_mode) {
                    gradientColorArr.push([parseInt(rStep*i+sColor[0]),parseInt(gStep*i+sColor[1]),parseInt(bStep*i+sColor[2])]);
                }else {
                    gradientColorArr.push(this.rgbToHex(parseInt(rStep*i+sColor[0]),parseInt(gStep*i+sColor[1]),parseInt(bStep*i+sColor[2])));
                }
            }
            return gradientColorArr;
        },
        //获取元素的纵坐标
        getTop(e){
            let offset=e.offsetTop;
            if(e.offsetParent!=null) offset+=this.getTop(e.offsetParent);
            return offset;
        },
        //获取元素的横坐标
        getLeft(e){
            let offset=e.offsetLeft;
            if(e.offsetParent!=null) offset+=this.getLeft(e.offsetParent);
            return offset;
        },
        getParentLeft(e) {
            if(e.offsetParent!=null) return e.offsetParent.offsetLeft;
            return 0
        },
        parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        },
        getTime (time, fmt) {
            if (!time) return ''

            const date = new Date(time * 1000)

            const o = {
                'M+': date.getMonth() + 1,               // 月份
                'd+': date.getDate(),                    // 日
                'h+': date.getHours(),                   // 小时
                'm+': date.getMinutes(),                 // 分
                's+': date.getSeconds(),                 // 秒
                'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
                'S': date.getMilliseconds()             // 毫秒
            }
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
            }
            for (var k in o) {
                if (new RegExp('(' + k + ')').test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
                }
            }
            return fmt
        }
    },
}

export {
    mixins
}
