export default {
    number: 'Flight Number',
    wake_interval: 'Aircraft Wake Interval',
    land_runway: 'Land Runway',
    land_airport: 'Aerodrome Landing',
    land_time: 'Land Time',
    land_date: 'Land Time',
    land_use_time: 'Land Use Time',
    seat: 'Seat',
    arrival_stand_time: 'Arrival Stand Time',
    arrival_stand_use_time: 'Arrival Stand Use Time',
    leave_time: 'Leave Time',
    leave_date: 'Leave Date',
    leave_runway: 'Leave Runway',
    leave_use_time: 'Leave Use Time',
    operation: 'Operation',
    add_flight: 'Add Flight',
    edit_flight: 'Edit Flight',
    title: 'Flight List',
    start_time:"Start Time",
    end_time:"End Time",
    selectAll:"Select all flights",
    selectTime:"Select a flight within the time range",
    preview:"Flight Preview",
    confirm:"modification",
    cancel:"cancel",
    gantt:"Gantt",
    type:"Type",
    current_time:"Current Time",
    lat:"Abscissa",
    lng:"Ordinate",
    gate:"Gate",
    arrival_time:"Arrival Time",
    runway:"Runway",
    predict_time:"Predict land/leave Time",
    fly_time:"Actual land/leave time",
    slipping_time:"Slipping time",
    switch_view:"Switch View",
    year:"Year",
    month:"Month",
    day:"Day",
    hour:"Hour",
    minute:"Minute",
    edit_success_flight:"Modified flight succeeded",
    add_success_flight:"Flight added successfully",
    delete_flight:"Are you sure to delete this flight",
    error_mes:"Please select the airport first",
    edit_success:"Modify successfully",
    land_taxiing_tiem: 'land taxiing time',
    takeoff_time: 'takeoff time',
    takeoff_taxiing_time: 'takeoff taxiing time',
    takeoff_runway: 'takeoff runway',
    takeoff_seat: 'takeoff seat',
    takeoff_airport: 'takeoff airport',
    land_seat: 'land seat',
    origin: 'Origin',
    destination: 'Destination',
    time: 'Time',
    to: 'to',
    aircraft_id: 'Select Aircraft',
    edit_error:"Fail to modify",
    edit_none:"You haven't modified it yet"
}
