import { request } from '@/utils'

// 添加机场
export const add_info = data => request.post('/api/airport/', data)

// 获取机场列表
export const get_list = params => request.get('/api/airport/', { params })

// 修改机场
export const edit_info = data => request.patch(`/api/airport/${data.id}/`, data)

// 获取机场详情
export const get_info = id => request.get(`/api/airport/${id}/`)

// 删除机场
export const del_info = id => request.delete(`/api/airport/${id}/`)
